<template>
  <div class="Integral">
    <div v-loading="loading"></div>
    <div class="innovationContest-banner">
      <banner-swiper :banners="bannerList" :bannerText="bannerText"></banner-swiper>
      <div class="integral_content">
        <div class="integral_head flex9">
          <div class="integral_head_l">
            <div class="integral_infos flex">
              <div class="infos_ss">
                {{Infos.name}}
              </div>
              <div class="integral_person flex0">
                <i class="el-icon-coin"></i>
                <span class="score">{{Infos.money}}</span>
              </div>
            </div>
            <div class="more_integral">
              获取更多积分，兑换奖品，立即加入剑盟社区
            </div>
          </div>
          <div class="integral_head_r flex0 cur" @click="member(1)">
            申请成为剑盟社区会员
          </div>
        </div>
        <!-- 中奖记录 -->
        <div class="Integral-draw-record mt15">
          <h1 class="tc mb15 Integral-module-title">中奖记录</h1>
          <div class="DrawRecord">
            <div v-if="resData.list.length" class="DrawRecord-list">
              <div class="DrawRecord-item" v-for="(item,index) in resData.list" :key="index" :class="clientWidth > 768 ? 'flex3': ''">
                <div> 
                  恭喜您在活动中抽中 {{item.title}} *1
                </div>
                <div :class="clientWidth < 769 ? 'mt5 DrawRecord-item-time': ''">{{item.createTime}}</div>
              </div>
            </div>
            <div v-else class="noData">
              <img src="@/assets/PC/profile/listNone.png" alt="" />
            </div>
          </div>
        </div>

        <div class="integral_foot">
          <h1 class="tc mb15 Integral-module-title">积分记录</h1>
          <table style="width:100% ;margin-bottom:10px">
            <tr class="flex6" v-for="(item,index) in IntegralList.data " :key="index">
              <td>{{item.name}}</td>
              <td><span>{{item.num.substring(1)}}</span> 积分</td>
              <td>{{item.time}}</td>
            </tr>
          </table>
          <div v-if="IntegralList.data.length>0 && IntegralList.recordCount > 3" class="page tr">
              <el-pagination @current-change="handleCurrentChange" :total="IntegralList.recordCount"
                :page-size="IntegralList.pageSize" :current-page="IntegralList.pageIndex" background layout="pager, next"
                next-text="下一页" hide-on-single-page>
              </el-pagination>
          </div>
          <div v-if="IsloadList" class="loadList flex0">
              <div class="loadList0" @click="loadlist">加载更多</div>
          </div>
          <div v-if="clientWidth < 750 && !IsloadList && IntegralList.data.length>0" class="tc notDetail">
            <div>
              没有更多内容了~
            </div>
          </div>
          <div class="integral_but flex0">
            <el-button type="info" class="conver" @click="crossExchange">积分兑换</el-button>
            <el-button type="info" class="conver_r" @click="IntegralDraw">积分抽奖</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="giveLive flex0" v-show="Show">
    <div class="invite-friends-content">
      <i class="el-icon-circle-close close-alert co-fff cur" @click="closeGift(1)"></i>
      <h1 class="invite-title tc">注册剑盟社区</h1>
      <h3 class="tc moregift">获取更多积分，<span style="color:#6b04a8">兑换好礼！</span></h3>
      <div class="live_but flex0">
        <div class="live_but_l cur tc" @click="member(2)">
          申请成为剑盟社区会员
        </div>
      </div>
    </div>
    </div>
    <div class="giveLive flex0" v-show="DrawShow">
    <div class="invite-friends-content">
      <i class="el-icon-circle-close close-alert co-fff cur" @click="closeGift(2)"></i>
      <h3 class="tc moregift giftchang">直播当天开放转盘抽奖，</h3>
      <h1 class="invite-title tc fetch"><span style="color:#6b04a8">更多好礼</span>等你来拿！</h1>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dataState } from "@/api/all.js";
import BannerSwiper from "@/views/Live/bannerSwiper.vue";
import { LiveBanner, MyLuckyDraw } from "@/api/InnovationContest.js";
import { UpdateUserType, GetRecord } from "@/api/profile.js";
import { common_msg_update, Update_Info_gold } from "@/utils/common.js";
export default {
  name: "Innovate",
  data() {
    return {
      // !banner展示图
      bannerList: [],
      // !banner指示器文字
      bannerText: [],
      Show: false,
      loading: true,
      DrawShow: false,
      IntegralList: {
        data: [], // !数据
        recordCount: 0, // !总条数
        pageIndex: 1, // !当前页数
        pageSize: 10, // !每页条数
      },
      IsloadList: false, // !移动端点击加载更多
      // !中奖记录数据
      resData: {
        list: [],
      },
    };
  },
  mounted() {
    if(!this.token) {
      this.$router.push('/Home/Live')
      return false
    } else if(this.Infos.type == 0) {
      this.$router.push('/Home/Live');
      return false
    }
    Update_Info_gold()
    this.GetInitDraw()
    this.GetInit();
    this.GetBannerList();
    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "Integral",
      relationId: "",
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  methods: {
    // !banner 接口
    GetBannerList() {
      LiveBanner().then((res) => {
        if (res.status === 1) {
          res.data.map((item) => {
            if (this.clientWidth > 750) {
              this.bannerList.push({
                imgUrl: item.imgUrl,
                url: item.redirectUrl,
              });
            } else {
              this.bannerList.push({
                imgUrl: item.imgUrl_m,
                url: item.redirectUrl,
              });
            }
            this.bannerText.push({
              subTitle: item.subTitle,
              title: item.title,
            });
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
    member(type) {
      UpdateUserType().then((res) => {
        if (res.status === 1) {
          common_msg_update();
          this.$toast.success("申请成功");
          this.$router.push("/Home/Profile/taskCenter");
          if (type == 2) {
            this.Show = false;
          }
        }
      });
    },
    closeGift(type) {
      if (type == 1) {
        this.Show = false;
      } else if (type == 2) {
        this.DrawShow = false;
      }
    },
    crossExchange() {
      this.Show = true;
    },
    IntegralDraw() {
      this.DrawShow = true;
    },
    GetInit() {
      this.loading = true;
      const data = {
        type: 0,
        limit: 10,
      };
      GetRecord(data).then((res) => {
        if (res.status === 1) {
          this.IntegralList = res;
          this.loading = false;
          if (this.IntegralList.recordCount > 10) {
            this.IsloadList = true;
          } else {
            this.IsloadList = false;
          }
        }
      });
    },
    GetInitDraw() {
      MyLuckyDraw().then(res => {
        if(res.status === 1) {
          this.resData.list = res.data
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !分页
    handleCurrentChange(currentPage) {
      this.loading = true;
      const data = {
        page: currentPage,
        limit: 10,
        type: 0,
      };
      // this.IntegralList = {
      // 	data: [],
      // 	recordCount: 0,
      // 	pageIndex: 1,
      // 	pageSize: 10,
      // };
      GetRecord(data).then((res) => {
        this.IntegralList = res;
        this.loading = false;
      });
    },
    // !点击加载更多
    loadlist() {
      this.loading = true;
      this.IntegralList.pageIndex++;
      const data = {
        type: 0,
        page: this.IntegralList.pageIndex,
        limit: 10,
      };
      GetRecord(data).then((res) => {
        if (this.IntegralList.recordCount > this.IntegralList.pageIndex * 10) {
          this.IsloadList = true;
        } else {
          this.IsloadList = false;
        }
        this.IntegralList.data = this.IntegralList.data.concat(res.data);
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapState(["clientWidth", "token", "Infos"]),
  },
  components: {
    BannerSwiper,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>