<template>
  <div class="banner-swiper">
    <swiper class="swiper-containers swiper-no-swiping" ref="mySwiper" :options="swiperOption" :auto-play="true">
      <swiper-slide v-for="(item, index) in banners" :key="index" class="swiper-item">
        <div>
          <a v-if="item.url" :href="item.url" target="_blank">
            <img :src="item.imgUrl" alt="" width="100%"/>
          </a>
          <img v-else :src="item.imgUrl" alt=""  width="100%"/>
        </div>
      </swiper-slide>
      <div class="swiper-pagination fx11" slot="pagination"></div>
    </swiper>
</div>
  
</template>
<script>

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ['banners', 'bannerText'],
  data() {
    return {
      swiperOption: {
        initialSlide: 1,
        speed: 1000, //匀速时间
        autoplay: false,
        loop: true,
        noSwiping: this.banners.length > 1 ? false : true,
        autoplay: {
          delay: 6000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        effect: "creative",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: (index, className) => {
            return `
              <div class="pagination-item ${className}">
                <div class="pagination-item-l">
                  <div class="flex0"> 
                    <div style="white-space: nowrap"> 0${index + 1}</div>
                    <div class="pagination-item-icon-line"></div>
                  </div>
                </div>
                <div class="pagination-item-r hidden">
                  <h3 class="tl mb hidden">${ this.bannerText.length ? this.bannerText[index].title : ''} </h3>
                  <p class="tl hidden">${ this.bannerText.length ? this.bannerText[index].subTitle : '' }</p>  
                </div> 
              </div>
            `
          },
        }
      },
    };
  }
};
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .banner-swiper {
    .pagination-item { width: 274px; height: 85px; background: rgba(0, 0, 0, 0.1); margin: 0 5px; color: #fff; border-radius: 0; padding: 20px; display: flex }
    .swiper-pagination-bullets { bottom: 20px }
    .swiper-pagination-bullet { opacity: inherit }
    .swiper-pagination-bullet-active { background: rgba(0, 0, 0, .5) }
    .pagination-item-icon-line { width: 10px; height: 2px; background: linear-gradient(to left, #f70756, #ffbb01); margin: 0 10px 0 5px }
  }

  @media (max-width: 750px) {
    .banner-swiper {
      .swiper-pagination-bullets { bottom: vw(50) }
      .pagination-item { width: vw(96); height: vw(70); padding: vw(15); }
      .pagination-item-l { display: flex }
      .pagination-item-r { display: none }
    }
  }
</style>
